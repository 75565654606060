<template>
  <div class="footer">
    <div class="footer-content">
      <div class="footer-content_hd">
        <div class="footer-content_hd-left">
          <div class="list">
            <div class="list-title">友情链接</div>
            <a href="https://www.seebon.com/" target="_blank" class="list-item">
              仕邦官网
            </a>
            <a
              href="https://weibo.com/5748602509/profile?topnav=1&wvr=6"
              target="_blank"
              class="list-item"
            >
              优秀青年官微
            </a>
            <a
              href="https://www.wanqubaoxian.com/#/index"
              target="_blank"
              class="list-item"
            >
              湾区保险
            </a>
          </div>
          <div class="list" style="margin-left: 30px">
            <div class="list-title">联系我们</div>
            <router-link to="/aboutUs" class="list-item">
              关于我们
            </router-link>
            <router-link to="/partnerPlan" class="list-item">
              加入我们
            </router-link>
          </div>
          <div class="list">
            <div class="list-title">了解我们</div>
            <router-link to="/flexibleEmployment" class="list-item"
              >零工通</router-link
            >
            <router-link to="/crowdSourcingServices" class="list-item"
              >灵活用工</router-link
            >
            <router-link to="/hroServices" class="list-item"
              >HRO服务</router-link
            >
            <router-link to="/recruitService" class="list-item"
              >招聘服务</router-link
            >
            <a href="https://www.youxiuqingnian.com/chart" class="list-item"
              >人才地图</a
            >
            <!--           <a class="list-item">商保代理</a>-->
          </div>
          <div class="list">
            <!-- <div class="list-title">全国统一客服热线</div>
            <div class="list-item phone">
              <img src="./icon-phone.png" />
              020-89817274
            </div> -->
            <div class="list-item">周一至周五 9:00—18:00</div>
            <div class="list-item address">
              <img src="./icon-add.png" />
              <div class="address-flex-item">
                <div class="left-label">广州总部：</div>
                <div class="right-value">
                  南沙区海滨路167号（南沙国际人才港）<br/>1402-1406房
                </div>
              </div>
            </div>
            <div class="list-item address">
              <img src="./icon-add.png" />
              <div class="address-flex-item">
                <div class="left-label">深圳分部：</div>
                <div class="right-value">
                  南山区粤兴四道北京航空航天大厦1号楼1606
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-content_hd-right">
          <div class="list-title">扫码关注</div>
          <div class="footer-content_b">
            <div class="footer-content_b-left">
              <!-- <div
                :class="{ on: QRCodeType === QR_CODE_TYPE.SERVICE }"
                class="qr-code-name"
                @mouseover="changeQRCode(QR_CODE_TYPE.SERVICE)"
              >
                优秀青年公众号
              </div> -->
              <div
                :class="{ on: QRCodeType === QR_CODE_TYPE.MINI_APP }"
                class="qr-code-name"
                @mouseover="changeQRCode(QR_CODE_TYPE.MINI_APP)"
              >
                优秀青年小程序
              </div>
              <div
                :class="{ on: QRCodeType === QR_CODE_TYPE.SHITONG }"
                class="qr-code-name"
                @mouseover="changeQRCode(QR_CODE_TYPE.SHITONG)"
              >
                南方仕通公众号
              </div>
            </div>
            <div class="footer-content_b-right">
              <div class="qr-code" v-show="QRCodeType === QR_CODE_TYPE.SERVICE">
                <g-image
                  g-src="assets/QRCode/wechatQRcode.png"
                  alt=""
                ></g-image>
              </div>
              <div
                class="qr-code"
                v-show="QRCodeType === QR_CODE_TYPE.MINI_APP"
              >
                <g-image g-src="assets/QRCode/appQRcodeC.jpg" alt=""></g-image>
              </div>
              <div class="qr-code" v-show="QRCodeType === QR_CODE_TYPE.SHITONG">
                <g-image g-src="assets/QRCode/yxqn-QRCode-v2.jpg" alt=""></g-image>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-content_bd">
      <p>
        <a :href="$baseInfo.icpUrl" target="_blank">
          增值电信业务经营许可证：{{ $baseInfo.icpNo }}
        </a>
        &nbsp;&nbsp;
        <a href="https://beian.miit.gov.cn" target="_blank">
          {{ $baseInfo.beian }}
        </a>
      </p>
      <p style="margin-top: 10px">
        广州南方仕通网络科技有限公司(仕邦集团旗下公司)版权所有Copyright ©
        2018-2020 STONE.All Rights Reserved
      </p>
    </div>
  </div>
</template>

<script>
import { ICPNUM } from 'api/config'
const QR_CODE_TYPE = {
  // SERVICE: 1,
  MINI_APP: 2,
  SHITONG: 3
}
export default {
  name: 'footerCopyright',
  data() {
    return {
      QR_CODE_TYPE,
      ipcNum: ICPNUM,
      QRCodeType: QR_CODE_TYPE.MINI_APP
    }
  },
  methods: {
    changeQRCode(type) {
      this.QRCodeType = type
    }
  }
}
</script>

<style scoped lang="less">
@import "~@/styles/customMixin.less";
.footer {
  z-index: 1;
  background-color: #354060;
  padding-top: 62px;
  position: absolute;
  width: 100%;
  overflow: hidden;
  left: 0;
  bottom: 0;
  .footer-content {
    width: 1200px;
    margin: 0 auto;
    .footer-content_hd {
      display: flex;
      justify-content: space-between;
      .footer-content_hd-left {
        display: flex;
        .list {
          min-width: 105px;
          border-left: 1px solid rgba(255, 255, 255, 0.3);
          padding-left: 20px;
          line-height: 1;
          & + .list {
            margin-left: 10px;
          }
          .list-title {
            font-size: 16px;
            color: #fff;
          }
          .list-item {
            color: rgba(255, 255, 255, 0.3);
            font-size: 14px;
            margin-top: 20px;
            position: relative;
            display: flex;
            align-items: center;
            img {
              margin-right: 5px;
              width: 9px;
              height: 12px;
            }
            &.phone {
              font-size: 20px;
              color: #ffffff;
              img {
                width: 13px;
                height: 15px;
              }
            }
            &.address {
              align-items: flex-start;
              line-height: 1.2;
              img {
                margin-top: 3px;
              }
              .address-flex-item {
                display: flex;
                // flex-wrap: wrap;
                .left-label {
                  width: 70px;
                }
                .right-value {
                  flex: 1;
                }
              }
            }
            &:before {
              content: "";
              height: 10px;
              width: 1px;
              background-color: #fff;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: -21px;
            }
          }
          a.list-item {
            cursor: pointer;
            &:hover {
              color: rgba(25, 157, 255, 1);
              text-decoration: underline;
            }
          }
        }
      }
      .footer-content_hd-right {
        .flex-column-normal();
        width: 300px;
        margin-left: 60px;
        .list-title {
          font-size: 16px;
          color: #fff;
        }
        .footer-content_b {
          .flex-normal();
          box-sizing: border-box;
          padding-top: 10px;
        }
        .footer-content_b-left {
          .flex-column-normal();
          align-items: center;
          justify-content: space-between;
          .qr-code-name {
            width: 112px;
            height: 30px;
            font-size: 12px;
            box-sizing: border-box;
            margin-bottom: 26px;
            line-height: 30px;
            text-align: center;
            border: 1px solid rgba(255, 255, 255, 0.3);
            color: rgba(255, 255, 255, 0.3);
            cursor: pointer;
            transition: 0.3s;
            &:last-child {
              margin-bottom: 0;
            }
            &.on {
              background-color: rgba(255, 255, 255, 0.3);
              color: #fff;
            }
          }
        }
        .footer-content_b-right {
          .flexMixin();
          width: 160px;
          height: 160px;
          margin-left: 20px;
          border: 1px solid rgba(255, 255, 255, 0.3);
        }
        .qr-code {
          .flexMixin();
          background: #ffffff;
          width: 141px;
          height: 141px;
          img {
            width: 129px;
            height: 129px;
          }
        }
      }
    }
  }
  .footer-content_bd {
    background-color: #2a334e;
    margin-top: 30px;
    text-align: center;
    font-size: 12px;
    line-height: 1;
    color: rgba(255, 255, 255, 0.3);
    padding: 25px 0 30px;
    a {
      color: rgba(255, 255, 255, 0.3);
    }
  }
}
</style>
