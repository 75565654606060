import { render, staticRenderFns } from "./footerCopyright.vue?vue&type=template&id=8a4b29cc&scoped=true"
import script from "./footerCopyright.vue?vue&type=script&lang=js"
export * from "./footerCopyright.vue?vue&type=script&lang=js"
import style0 from "./footerCopyright.vue?vue&type=style&index=0&id=8a4b29cc&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a4b29cc",
  null
  
)

export default component.exports