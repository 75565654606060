<template>
  <div class="header">
    <div class="header-container clearfix">
      <router-link tag="div"
        :to="{path: '/index'}"
        class="logo">
        <g-image
          width="175"
          g-src="assets/pageConfig/logo.png"
          alt=""
        />
        <div class="logo-text">
          <g-image
            height="45"
            g-src="page/homePage/views/index/components/navHeader/nfst.png"
            alt=""
          />
        </div>
      </router-link>
      <div class="header-nav">
        <div class="clearfix nav-container">
          <template  v-for="item in navDataListMap">
            <template v-if="!item.linkUrl">
              <router-link
                tag="a"
                ref="li"
                class="nav-item"
                v-if="!item.child"
                :to="{ path: item.path }"
                :key="item.index"
                :class="{on: navKeyMap[routePath] === item.index}"
              >
                {{item.name}}
              </router-link>
              <div
                :key="item.index"
                ref="li"
                :class="{
                  on: navKeyMap[routePath] === item.index,
                  'nav-item-icon': item.icon
                }"
                v-if="item.child"
                class="nav-item nav-item-down"
              >
                <img
                  :src="item.icon"
                  v-if="item.icon"
                  class="icon-style"
                  alt=""
                >
                {{item.name}}
                <div class="arrows"></div>
                <div class="down-content">
                  <div
                    class="down-col"
                    v-for="ch_item in item.child"
                    :key="ch_item.index"
                  >
                    <div class="down-col-name">
                      {{ ch_item.name }}
                    </div>
                    <router-link
                      tag="div"
                      :to="{ path: n.path }"
                      :class="{on: routePath.indexOf(n.path) > -1}"
                      class="down-nav_li"
                      v-for="n in ch_item.child"
                      :key="n.index"
                    >
                      <img
                        :src="n.icon"
                        v-if="n.icon"
                        class="icon-style"
                        alt=""
                      >
                      {{ n.name }}
                    </router-link>
                  </div>
                </div>
              </div>
            </template>
            <a v-else
               ref="li"
               class="nav-item"
               :target="item.target"
               :key="item.linkUrl"
               :href="item.linkUrl">
              <img :class="item.className"
                   v-if="item.icon"
                   :src="item.icon">
              {{item.name}}
            </a>
          </template>
          <div class="nav-on-line" ref="navOnLine"></div>
        </div>
      </div>
      <div
        class="header-login"
        v-if="routePath === '/settlementServices'"
      >
        <a style="margin-left: 24px" class="login-btn" target="_blank" :href="saasUrl">进入结算中心</a>
      </div>
      <template v-else-if="$PRERENDER_INJECTED">
        <div class="header-login" v-if="!isLogin">
          <div
            @click="showOptimumProposal"
            class="default-btn btn-FFDD00"
          >
            免费体验
          </div>
          <div
            @click="$linkInsidePages({path:'/login', query: {type:loginType.type}})"
            class="btn-a"
          >
            登录
          </div>
        </div>
        <div class="header-tip" v-if="isLogin">
          <div id="header-nav">
            <div class="header-info" ref="headerInfo" @click="centrePage">
              <div
                v-if="userData.logo === ''&& !userData.logo"
                class="header-nav-logo default-logo"
              >
              </div>
              <div v-else class="header-nav-logo"
                   :style="`background-image: url(${userData.logo});background-size:100% 100%;`">
              </div>
              <div class="header-nav-info">
                <div class="info-phone">
                  {{userData.phone | subStrStar}}
                </div>
                <div class="info-company">
                  {{userData.companyName | subStr(7)}}
                </div>
              </div>
            </div>
            <div class="header-nav-content">
              <div class="header-nav-list-page">
                <div class="header-nav-list ">
                  <div
                    v-permission="PERMISSIONS.companyInfo"
                    @click="$linkInsidePages({path: '/set/company-info'})"
                    class="nav-list-item icon-company-info">
                    企业资料
                    <span v-if="!checkFlag">待完善</span>
                  </div>
                  <div
                    v-permission="PERMISSIONS.companyCert"
                    class="nav-list-item icon-auth"
                    @click="$linkInsidePages({path: '/company-cert/company-cert'})">
                    企业认证
                    <span v-if="userData.authStatus !== 2">待认证</span>
                    <span v-else>已认证</span>
                  </div>
                  <div
                    v-permission="{
                        permission: [PERMISSIONS.APIManagement],
                        condition: [userData.crowdsource]
                      }"
                    @click="$linkInsidePages({path: '/APIManagement/APIManagement'})"
                    class="nav-list-item icon-phone">
                    开发者中心
                  </div>
                  <div
                    v-permission="PERMISSIONS.modifyPhone"
                    @click="$linkInsidePages({path: '/security/modify-phone'})"
                    class="nav-list-item icon-phone">
                    修改登录手机号
                  </div>
                  <div
                    v-permission="PERMISSIONS.modifyPassword"
                    @click="$linkInsidePages({path: '/security/modify-password'})"
                    class="nav-list-item icon-password">
                    修改登录密码
                  </div>
                  <div @click="logOut" class="nav-list-item icon-out">
                    退出登录
                  </div>
                </div>
              </div>
            </div>
            <i class="el-icon-arrow-down nav-arrow"></i>
          </div>
        </div>
      </template>
      <div v-else style="width: 238px"></div>
    </div>
  </div>
</template>

<script>
import { menuData, statusCode, saasUrl } from 'api/config'
import { mapGetters } from 'vuex'
import { getCompanyInfo } from 'api/job'
import { getToken } from '@/utils/auth'

const iconNew = require('./icon-new.png')
const navDataList = [
  {
    path: '/index',
    name: '首页'
  },
  {
    name: '产品与方案',
    icon: iconNew,
    child: [
      {
        name: '灵活用工',
        child: [
          {
            path: '/flexibleEmployment',
            name: '零工通',
            icon: iconNew
          },
          {
            path: '/crowdSourcingServices',
            name: '灵活用工'
          },
          {
            path: '/settlementServices',
            name: '结算服务'
          },
          {
            path: '/insuranceAgency',
            name: '灵工保险'
          }
        ]
      },
      {
        name: '企业工具',
        child: [
          {
            path: '/hroServices',
            name: 'HRO服务'
          }
        ]
      },
      {
        name: '蓝领招聘',
        child: [
          {
            path: '/recruitService',
            name: '招聘服务',
            otherPage: ['/index.html', '/heatJobDetails', '/heatCompanyDetails', '/chooseCity'] // 子页面
          }
        ]
      }
    ]
  },
  {
    path: '/openPlatform',
    name: '开放平台'
  },
  {
    path: '/partnerPlan',
    name: '合伙人计划'
  },
  {
    path: '/aboutUs',
    name: '关于我们'
  }
]

function generateNavKeyMap (arr, parentIndex = null) {
  const listMap = {}
  const keyMap = {}
  let child = {}
  for (let i = 0; i < arr.length; i++) {
    listMap[i] = {
      path: arr[i].path,
      name: arr[i].name,
      index: i,
      linkUrl: arr[i].linkUrl,
      unIcon: arr[i].unIcon,
      icon: arr[i].icon,
      className: arr[i].className,
      target: arr[i].target
    }
    if (arr[i].otherPage && arr[i].otherPage.length) {
      arr[i].otherPage.forEach(p => {
        keyMap[p] = parentIndex || i
      })
    }
    if (arr[i].child) {
      child = generateNavKeyMap(arr[i].child, parentIndex || i)
      listMap[i].child = child.listMap
      Object.assign(keyMap, child.keyMap)
    } else {
      keyMap[arr[i].path] = parentIndex || i
    }
  }
  return { listMap, keyMap }
}
const { listMap, keyMap } = generateNavKeyMap(navDataList)
// 兼容nginx配置
keyMap['/index/'] = keyMap['/index']
// console.info(listMap, keyMap)
const EVENT = {
  showOptimumProposal: 'showOptimumProposal'
}

export default {
  name: 'navHeader',
  data() {
    return {
      navKeyMap: keyMap, // 用于获取选中态
      navDataListMap: listMap,
      navDataList,
      checkFlag: true,
      saasUrl,
      lisEle: null
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ]),
    routePath () {
      return this.$route.path
    },
    /**
     * 登录类型
     */
    loginType () {
      const data = {
        '/crowdSourcingServices': {
          type: 'crowdSourcingServices',
          name: '登录',
          phone: '400-023-1288'
        },
        '/hroServices': {
          type: 'hro',
          name: '登录',
          phone: '400-023-1288'
        },
        '/recruitService': {
          type: 'recruit',
          name: '登录/注册',
          phone: '400-023-1288'
        }
      }
      return (data[this.$route.path] || data['/crowdSourcingServices'])
    },

    isLogin () {
      return getToken()
    }
  },
  created() {
    if (getToken()) {
      this.getCompanyInfo()
    }
  },
  mounted () {
    this.lisEle = this.$refs.li
    this.routerPush()
  },
  methods: {
    showOptimumProposal() {
      this.$emit(EVENT.showOptimumProposal, {})
    },
    logOut () {
      this.$store.dispatch('LogOut').then(res => {
        this.$linkInsidePages({ path: '/login' })
      }).catch(err => {
        this.$linkInsidePages({ path: '/login' })
        console.info(err)
      })
    },
    // 商家中心
    centrePage () {
      const pageType = sessionStorage.getItem('pageType') || 'crowdSourcingServices'
      this.$linkInsidePages({ path: menuData[pageType].path })
    },
    routerPush() {
      const navOnLine = this.$refs.navOnLine
      const index = this.navKeyMap[this.routePath]
      const target = this.lisEle[index].$el || this.lisEle[index]
      navOnLine.style.left = target.offsetLeft + 'px'
      navOnLine.style.width = target.clientWidth + 'px'
    },
    getCompanyInfo () {
      // 检查角色和企业资料
      getCompanyInfo().then(res => {
        if (res.code === statusCode.OK) {
          if (res.data && !res.data.abbr) {
            this.checkFlag = false
          }
        }
      }).catch(() => {
        this.$message.error('服务器错误!!')
      })
    }
  },
  watch: {
    $route() {
      this.routerPush()
    }
  }
}
</script>

<style scoped lang="less">
  @import "~@/styles/customMixin.less";
  .header{
    z-index: 3000;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 1280px;
    .header-container{
      width: 1200px;
      margin: 0 auto;
      height: 100px;
      display: flex;
      font-size: 16px;
      .logo{
        line-height: 1;
        cursor: pointer;
        box-sizing: border-box;
        height: 100px;
        display: flex;
        align-items: center;
        .logo-text {
          position: relative;
          margin-left: 50px;
          margin-top: -5px;
          &:before {
            content: '';
            position: absolute;
            height: 16px;
            left: -25px;
            top: 18px;
            box-sizing: border-box;
            border-left: 1px solid #303133;
          }
        }
        //img{
        //  width: 190px;
        //  height: 50px;
        //}
      }
      .header-nav{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        height: 100%;
        .nav-container {
          height: 100%;
          position: relative;
          display: flex;
          align-items: center;
          .nav-item{
            height: 100%;
            user-select: none;
            color: #303133;
            font-weight: 500;
            cursor: pointer;
            margin: 0 20px;
            position: relative;
            display: flex;
            align-items: center;
            transition: all .3s;
            &.nav-item-icon {
              margin-left: 57px;
            }
            .icon-style {
              position: absolute;
              width: 24px;
              height: 20px;
              left: -29px;
              top: 50%;
              transform: translateY(-65%);
            }
            &.nav-item-down{
              .arrows{
                transition: all .3s;
                position: relative;
                transform-origin: 5px 7.5px;
                transform: rotateZ(180deg) ;
                margin-top: -7px;
                top: 0;
                right: 0;
                margin-left: 5px;
                width: 0;
                height: 0;
                border: 5px solid #303133;
                border-top-color: transparent!important;
                border-right-color: transparent!important;
                border-left-color: transparent!important;
              }
            }
            .down-content{
              position: absolute;
              top: 100%;
              left: -60px;
              cursor: default;
              visibility: hidden;
              opacity: 0;
              transition: all .3s;
              z-index: 888;
              background-color: #fff;
              border-radius: 0 0 10px 10px;
              box-shadow: 0 12px 30px 0 rgba(64,158,255,0.07);
              display: flex;
              padding: 10px 60px 40px;
              .down-col {
                width: 100px;
                text-align: left;
                & + .down-col {
                  margin-left: 60px;
                }
                .down-col-name {
                  font-size: 20px;
                  padding: 20px 0;
                  color: #303133;
                  width: 100%;
                  border-bottom: 1px solid #C0C4CC;
                  font-weight: 500;
                }
                .down-nav_li{
                  position: relative;
                  margin-top: 20px;
                  font-size: 16px;
                  color: #606266;
                  cursor: pointer;
                  font-weight: 400;
                  &.on, &:hover {
                    color: #409EFF;
                  }
                }
              }
            }
            &:hover{
              color: #409EFF;
              .down-content{
                visibility: visible;
                opacity: 1;
              }
              &.nav-item-down{
                .arrows{
                  transform: rotateZ(0deg) ;
                  border: 5px solid #409EFF;
                }
              }
            }
            &.on {
              color: #409EFF;
              &.nav-item-down{
                .arrows{
                  border: 5px solid #409EFF;
                }
                &:after{
                  border: 5px solid #409EFF;
                }
              }
            }
          }
          .nav-on-line{
            content: '';
            position: absolute;
            bottom: 0;
            height: 2px;
            background-color: #409EFF;
            transition: all .3s;
          }
        }
      }
      .header-tip{
        display: flex;
        align-items: center;
        height: 100%;
        .logout{
          width: 20px;
          height: 20px;
          display: inline-block;
          background: url("~@/assets/global/out1.png") no-repeat center center;
          background-size: 100% 100%;
          vertical-align: middle;
          margin-left: 10px;
        }
        .router-link{
          color: #aaaaaa;
          font-size: 14px;
        }
        .centre-page{
          color: #fff;
          cursor: pointer;
          font-size: 14px;
        }
      }
      .header-login{
        margin-left: 50px;
        justify-self: flex-end;
        display: flex;
        align-items: center;
        .tel{
          color: #333;
          font-weight: 500;
          font-size: 16px;
        }
        .btn-FFDD00{
          width: 120px;
          height: 40px;
        }
        .btn-a {
          color: #333333;
          margin-left: 40px;
        }
      }
      // header 下拉导航
      #header-nav {
        height: 100%;
        margin-left: 40px;
        .header-nav-info {
          color: #303133;
        }
        .nav-arrow {
          color: #303133;
          margin-left: 1px;
        }
        &:hover .header-nav-content {
          visibility: visible;
          opacity: 1;
        }
        .header-nav-content {
          transform: translateY(0);
          left: 0;
          padding-top: 0;
          background-color: #fff;
          .header-nav-list-page {
            position: relative;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
            border-radius: 0 0 5px 5px;
            .header-nav-list {
              padding: 0;
              .nav-list-item {
                & + .nav-list-item {
                  border-top: 1px solid rgba(255, 255, 255, 0.1);
                }
                line-height: 50px;
                height: 50px;
                color: #606266;
                span {
                  background: #1C8CFF;
                  color: #fff;
                }
                &:hover {
                  color: #409EFF;
                  transition: .15s;
                  background-color: rgba(171, 202, 255, 0.2) !important;
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .header{
      position: absolute;
    }
  }
</style>
