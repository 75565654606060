<template>
  <div class="index">
   <navHeader
     @showOptimumProposal="showOptimumProposal"
   ></navHeader>

    <div class="app-container">
      <router-view v-if="!pageType"></router-view>
      <div v-if="pageType"  class="main-page">
        <section class="job-main">
          <div id="page-title" v-if="notShowTitle.indexOf($router.currentRoute.meta.title) === -1">
            <template v-if="isShowBreadCrumbs">
              <template v-for="(item, index) in breadCrumbs" >
                <router-link class="breadCrumbs" :key="item.pageName" :to="item">
                  {{item.pageName}}
                </router-link>
                <span class="jiantou-right" :key="index">/</span>
              </template>
            </template>
            {{title}}
          </div>
          <div v-if="isShowCityChoose" @click="jumpChooseCity" class="app-select-address ">
            <span class="address-text">{{cityName}}</span>
            <img src="./../../../../assets/newGlobal/jiantou3.png">
          </div>
          <router-view></router-view>
        </section>
      </div>
    </div>
    <rightBottomFloat
      ref="rightBottomFloat"
      v-if="pageConfig.showRightTool"
    ></rightBottomFloat>
    <footer-copyright></footer-copyright>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getToken } from '@/utils/auth' // getToken from cookie
import { BASEURL } from '@/api/config'
import { unreadNotice } from 'api/layout'
import { getCurrentPosition } from '@/utils'
import citySort from '@/javascript/city_sort'
import navHeader from './components/navHeader/navHeader'
import footerCopyright from './components/footerCopyright/footerCopyright'
import rightBottomFloat from '@/components/rightBottomFloat/rightBottomFloat'


export default {
  name: 'index',
  components: {
    navHeader,
    footerCopyright,
    rightBottomFloat
  },
  data () {
    return {
      unreadNotice: '',
      breadCrumbs: [], // 面包屑数据
      notShowTitle: ['热招岗位'],
      notShowBreadCrumbsArr: [], // 不显示面包屑
      isShowBreadCrumbs: true,
      title: '', // 当前标题
      tempBreadCrumbs: {}, // 临时
      cityData: {},
      cityName: '长沙',
      adCode: '430100',
      normalAdCode: '',
      normalName: '',
      getPositionPromise: null,
      getPositionInfo: {},
      tipPositionPath: ['/recruitService', '/index.html', '/heatJobDetails', '/heatCompanyDetails'] // 需要提示是否切换城市的页面
    }
  },
  computed: {
    ...mapGetters([
      'userData',
      'positionCityData',
      'salaryEpibolicComInfo',
      'pageConfig'
    ]),
    /**
     * 是否显示选择城市按钮
     */
    isShowCityChoose () {
      const path = ['/index.html']
      return path.indexOf(this.routePath) > -1
    },

    /**
     * 页面类型  热门岗位 / 非热门岗位
     */
    pageType () {
      const page = ['/index.html', '/chooseCity', '/heatCompanyDetails', '/heatJobDetails', '/chooseCity']
      return page.indexOf(this.$route.path) > -1
    },

    routePath () {
      return this.$route.path
    }
  },
  created () {
    if (getToken()) {
      unreadNotice().then(res => {
        this.$store.dispatch('setUnread', res.data)
      })
      this.$store.dispatch('GetInfo')
    }
    if (BASEURL === 'https://www.youxiuqingnian.com') {
      this.normalName = '长沙市'
      this.normalAdCode = '430100'
    } else {
      this.normalName = '深圳市'
      this.normalAdCode = '440300'
    }
    this.cityName = this.$route.query.cityName || this.normalName
    this.adCode = this.$route.query.adCode || this.normalAdCode
    this.setBreadCrumbs()
    this.getPositionPromise = this.positionChange()
    this.tipIsChangeCity(this.$route.path)
  },
  methods: {
    showOptimumProposal() {
      this.$refs.rightBottomFloat.showOptimumProposal()
    },
    /**
     * 设置面包屑
     */
    setBreadCrumbs () {
      // 配置项看router/home.js
      const tempBreadCrumbs = this.$router.currentRoute.query.tempBreadCrumbs
      let breadCrumbs = []
      if (this.tempBreadCrumbs[tempBreadCrumbs]) { // 临时面包屑
        if (this.tempBreadCrumbs[tempBreadCrumbs].parentPage) {
          breadCrumbs = this.tempBreadCrumbs[tempBreadCrumbs].parentPage || []
        }
        this.title = this.tempBreadCrumbs[tempBreadCrumbs].title
      } else { // 正常路由页面面包屑
        const currentRoute = this.$router.currentRoute
        if (currentRoute.meta.parentPage) {
          breadCrumbs = currentRoute.meta.parentPage.defaultPage || []
        }
        if (currentRoute.query[currentRoute.meta.isManyTitle]) {
          this.title = this.$router.currentRoute.meta.title2
        } else {
          this.title = this.$router.currentRoute.meta.title
        }
        this.isShowBreadCrumbs = this.notShowBreadCrumbsArr.indexOf(this.title) === -1
      }
      this.breadCrumbs = breadCrumbs.map(item => {
        if (item.urlKey && item.urlKey.length) {
          item.urlKey.map(i => {
            if (!item.query) {
              item.query = {}
            }
            item.query[i] = this.$router.currentRoute.query[i]
          })
        }
        return item
      })
    },

    jumpChooseCity () {
      //  跳城市选择时需要带上 this.$route.query
      this.$router.push({
        path: '/chooseCity',
        query: this.$route.query
      })
    },
    /**
     * 获取城市列表
     * @returns {Promise<T>}
     */
    getAdcode () {
      return new Promise(resolve => {
        this.cityData = this.initCity(citySort)
        resolve()
      })
    },
    /**
     * 提示切换城市
     */
    positionChange () {
      return Promise.all([getCurrentPosition, this.getAdcode()]).then(res => {
        if (res[0] && res[0].addressComponent) {
          this.getPositionInfo = this.cityData[res[0].addressComponent.adcode] || {
            adCode: ''
          }
          this.$store.commit('SET_POSITION_CITY_DATA', this.getPositionInfo)
          return Promise.resolve()
        } else {
          return Promise.reject()
        }
      }).catch(err => {
        console.info(err)
        return Promise.reject()
      })
    },
    /**
     * 提示是否切换城市
     */
    tipIsChangeCity (path) {
      this.getPositionPromise && this.getPositionPromise.then(() => {
        const positionCity = sessionStorage.getItem('positionCity')
        const adCode = this.getPositionInfo.directFlag === 21 ? this.getPositionInfo.adCode.substring(0, 3) : this.getPositionInfo.adCode.substring(0, 4)
        const flag = this.getPositionInfo.adCode !== this.adCode && this.getPositionInfo.fatherAdCode !== this.adCode && this.adCode !== adCode
        if (!(flag && !positionCity && this.tipPositionPath.indexOf(path) > -1)) {
          return
        }
        sessionStorage.setItem('positionCity', 'true')
        const cityName = this.getPositionInfo.fatherName || this.getPositionInfo.name
        this.$confirm(`您当前定位城市和选择的城市不一致，是否要切换到${cityName}？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'custom-el',
          type: 'warning'
        }).then(() => {
          this.$router.push({
            query: {
              cityName: cityName,
              adCode: adCode,
              areaAdCode: this.getPositionInfo.adCode,
              cityIndex: this.getPositionInfo.cityIndex
            }
          })
        }).catch(() => {
        })
      })
    },
    /**
     * 递归处城市列表结构 {adCode: cityData}
     * @param data
     * @param obj
     * @param fatherAdCode
     * @param fatherName
     * @param fatherIndex
     * @param fatherIsHot
     * @param flag
     */
    initCity (data, obj = {}, fatherAdCode = '', fatherName = '', fatherIndex = '', fatherIsHot = '', flag) {
      for (let i = 0; data.length > i; i++) {
        obj[data[i].adCode] = {
          adCode: data[i].adCode,
          name: data[i].fullName,
          cityIndex: flag ? fatherIndex : i,
          fatherAdCode: fatherAdCode,
          fatherName: fatherName,
          directFlag: data[i].directFlag || -1,
          isHot: data[i].isHot || fatherIsHot
        }
        if (data[i].areas) {
          this.initCity(data[i].areas, obj, data[i].adCode, data[i].name, i, data[i].isHot, true)
        }
      }
      return obj
    }
  },
  watch: {
    $route (f, t) {
      this.cityName = this.$route.query.cityName || this.normalName
      this.adCode = (this.$route.query.adCode || '').toString() || this.normalAdCode

      this.setBreadCrumbs()
      // 热招岗位筛选条件
      if (t.path === '/index.html' && (f.path === '/heatJobDetails' || f.path === '/heatCompanyDetails')) {
        window.sessionStorage.setItem('heatJobQuery', JSON.stringify(t.query))
      } else if (f.path === '/index.html' && (t.path === '/heatJobDetails' || t.path === '/heatCompanyDetails')) {
        const query = JSON.parse(window.sessionStorage.getItem('heatJobQuery'))
        this.$router.push({
          query: query
        })
      } else if (!(t.path === '/heatJobDetails' && f.path === '/heatCompanyDetails') && !(f.path === '/heatJobDetails' && t.path === '/heatCompanyDetails')) {
        window.sessionStorage.removeItem('heatJobQuery')
      }
      this.tipIsChangeCity(f.path)
    }
  }
}
</script>
<style lang="less">
</style>

<style lang="less" scoped>
  @import "index";

  .job-main {
    width: 1200px;
    margin: 0 auto;
    position: relative;
  }

  .app-container {
    .sidebar-container {
      float: left;
      width: 260px;
      margin-right: -100%;
      padding: 0 40px;
      box-sizing: border-box;
    }

    .main-page {
      width: 100%;
      margin-top: 30px;
    }

  }

</style>
